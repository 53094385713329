const ARTISTS_DATA = [
  {
    name: "Aaron Carmody",
    town: "Syracuse, New York",
    location: "Syracuse, New York",
    insta_handle: "rad_el_snake",
    email_address: "aaronnva@gmail.com",
    img: "artists/2023-Artists/Aaron-Carmody.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Abi Tonge",
    town: "Leeds",
    country: "UK",
    location: "Leeds, UK",
    insta_handle: "art.tattoos",
    email_address: "arttattoos@hotmail.com",
    img: "artists/2023-Artists/Abi-Tonge.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Adriana Oets",
    town: "Erlanger",
    country: "Germany",
    location: "Erlanger, Germany",
    insta_handle: "adri_olala",
    email_address: "info@tcnoire.com",
    img: "artists/2023-Artists/Adriana-Oets.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Alastair Magee",
    town: "Joburg",
    country: "SA",
    location: "Joburg, SA",
    insta_handle: "alastairmagee",
    email_address: "asta27@icloud.com",
    img: "artists/2023-Artists/Alastair-Magee.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Alex Muller",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "weskuswolf",
    email_address: "weskuswolftattoo@gmail.com",
    img: "artists/2023-Artists/Alex-Muller.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alex Steiger",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "alexsteiger",
    email_address: "alexsteigertattoos@gmail.com",
    img: "artists/2023-Artists/Alex-Steiger.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alex Travers",
    town: "London",
    country: "UK",
    location: "London, UK",
    insta_handle: "alextraverstattoo",
    email_address: "allybonestattooing@gmail.com",
    img: "artists/2023-Artists/Alex-Travers.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Anja Black",
    town: "Wellington",
    country: "RSA",
    location: "Wellington, RSA",
    insta_handle: "anjablack.ink",
    email_address: "Anja@blackblossom.co.za",
    img: "artists/2023-Artists/Anja-Black.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Anna Claire Travers",
    town: "London",
    country: "UK",
    location: "London, UK",
    insta_handle: "tigertitz",
    email_address: "annawhysall@gmail.com",
    img: "artists/2023-Artists/Anna-Claire-Travers.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Anna Mack",
    town: "Portland",
    country: "USA",
    location: "Portland, USA",
    insta_handle: "annamacktattoo",
    email_address: "anna@atlastattoo.com",
    img: "artists/2023-Artists/Anna-Mack.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Antonello Leuti",
    town: "Rome",
    country: "Italy",
    location: "Rome, Italy",
    insta_handle: "antonelloleuti",
    email_address: "Antonelloleuti91@gmail.com",
    img: "artists/2023-Artists/Antonello-Leuti.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Arianna Fusini",
    town: "Rimini",
    country: "Italy",
    location: "Rimini, Italy",
    insta_handle: "ariannafusini",
    email_address: "arianna.fusini@gmail.com",
    img: "artists/2023-Artists/Arianna-Fusini.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ashley Welman",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "trade_mark_tattoo",
    email_address: "ash_famous@hotmail.co.uk",
    img: "artists/2023-Artists/Ashley-Welman.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Aureole McAlpine",
    town: "Queensland",
    country: "Australia",
    location: "Queensland, Australia",
    insta_handle: "aureoletattoo",
    email_address: "aureoleart@gmail.com",
    img: "artists/2023-Artists/Aureole-Mc-Alpine.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Austin Maples",
    town: "San Francisco",
    country: "USA",
    location: "San Francisco, USA",
    insta_handle: "austinmaples",
    email_address: "austinmaplestattoos@gmail.com",
    img: "artists/2023-Artists/Austin-Maples.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Berly Boy",
    town: "Milano",
    country: "Italy",
    location: "Milano, Italy",
    insta_handle: "berly.boy",
    email_address: "berlyb666y@gmail.com",
    img: "artists/2023-Artists/Berly-Boy.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Bernadee Bibble",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "bernadeeawhetattoos187",
    email_address: "awhetattoo@gmail.com",
    img: "artists/2023-Artists/Bernadee-Bibble.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Bert Raymakers",
    town: "Hasselt",
    country: "Belgium",
    location: "Hasselt, Belgium",
    insta_handle: "bertdaggermoon",
    email_address: "bert.daggermoon@gmail.com",
    img: "artists/2023-Artists/Bert-Raymakers.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Bob Geerts",
    town: "Eindhoven",
    country: "Netherlands",
    location: "Eindhoven, Netherlands",
    insta_handle: "bobeustattoo",
    email_address: "Info@trueblue-tattoo.com",
    img: "artists/2023-Artists/Bob-Geerts.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Byron Barker",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "byronbarkertattoos",
    email_address: "byronbarker13@gmail.com",
    img: "artists/2023-Artists/Byron-Barker.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Calvin Smorenburg",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "calvinsteps",
    email_address: "calvinsmorenburg@gmail.com",
    img: "artists/2023-Artists/Calvin-Smorenburg.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Carrie Buckley",
    town: "Thatcham",
    country: "UK",
    location: "Thatcham, UK",
    insta_handle: "carrie_tattoos",
    email_address: "Carriebuckleytattoos@gmail.com",
    img: "artists/2023-Artists/Carrie-Buckley.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Chad Koeplinger",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "Chadkoeplingertattoo",
    email_address: "adventuretattoo@gmail.com",
    img: "artists/2023-Artists/Chad-Koeplinger.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Chanre Joubert",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg, RSA",
    insta_handle: "theneedledance",
    email_address: "info@theneedledance.com",
    img: "artists/2023-Artists/Chanre-Joubert.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Charl Steyn",
    town: "Pretoria",
    country: "RSA",
    location: "Pretoria, RSA",
    insta_handle: "skipper_87_tattooing",
    email_address: "012tattoostudio@gmail.com",
    img: "artists/2023-Artists/Charl-Steyn.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Chaz Otto",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "dirtbagxchaz",
    email_address: "dirtbagxchaz@yahoo.com",
    img: "artists/2023-Artists/Chaz-Otto.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Chris De Villiers",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "chrisdeviltattoos",
    email_address: "chrisdeviltattoos@gmail.com",
    img: "artists/2023-Artists/Chris-De-Villiers.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Clemens Hahn",
    town: "Mannheim",
    country: "Germany",
    location: "Mannheim, Germany",
    insta_handle: "clemens_hahn",
    email_address: "clemens@trulyyourstattoo.com",
    img: "artists/2023-Artists/Clemens-Hahn.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Courtney O'Shea",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "courtneykotattoo",
    email_address: "courtneytattoos@gmail.com",
    img: "artists/2023-Artists/Courtney-O-Shea.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dan Gilsdorf",
    town: "Portland",
    country: "USA",
    location: "Portland, USA",
    insta_handle: "dangilsdorf",
    email_address: "dan@atlastattoo.com",
    img: "artists/2023-Artists/Dan-Gilsdorf.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Daniel Feinberg",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg, RSA",
    insta_handle: "danielfeinberg",
    email_address: "diyadanjen@gmail.com",
    img: "artists/2023-Artists/Daniel-Feinberg.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Daniel Hughes",

    town: "Nashville",
    location: "Nashville",
    insta_handle: "danielhughestattoos",
    email_address: "Danielhughestattooer@gmail.com",
    img: "artists/2023-Artists/Daniel-Hughes.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Daniele Scafati",
    town: "Rome",
    country: "Italy",
    location: "Rome, Italy",
    insta_handle: "danielescafati",
    email_address: "er.daniele@hotmail.it",
    img: "artists/2023-Artists/Daniele-Scafati.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Darryl Hart",
    town: "New Brunswick",
    country: "Canada",
    location: "New Brunswick, Canada",
    insta_handle: "dhart_tl",
    email_address: "hightides.dhart@gmail.com",
    img: "artists/2023-Artists/Darryl-Hart.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "David Chaston",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "davidchastontattoo",
    email_address: "Davidchastontattoo@gmail.com",
    img: "artists/2023-Artists/David-Chaston.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "David Hoffman",
    town: "South Jordan",
    country: "USA",
    location: "South Jordan, Utah, USA",
    insta_handle: "davemantattoos",
    email_address: "davemantattoos@yahoo.com",
    img: "artists/2023-Artists/David-Hoffman.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "David Pollen",
    insta_handle: "azndavetattoo",
    email_address: "dpollen123@gmail.com",
    img: "artists/2023-Artists/David-Pollen.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dean Clarke",
    town: "Pretoria",
    country: "SA",
    location: "Pretoria, SA",
    insta_handle: "deanclarkeart",
    email_address: "tonycreations@gmail.com",
    img: "artists/2023-Artists/Dean-Clarke.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Devin Thomas",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "tightropethomas",
    email_address: "tightropethomas@gmail.com",
    img: "artists/2023-Artists/Devin-Thomas.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Dimitris Dimopoulos",
    country: "Greece",
    location: "Greece",
    insta_handle: "Cobra_tattoo",
    email_address: "wtl.tattoo@gmail.com",
    img: "artists/2023-Artists/Dimitris-Dimopoulos.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dokter Irie",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "dokter_iriezumi",
    email_address: "dokteririe@gmail.com",
    img: "artists/2023-Artists/Dokter-Irie.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Erik Smiley",
    town: "Costa Rica",
    location: "Costa Rica",
    insta_handle: "smileytattoo",
    email_address: "esmiley5987@gmail.com",
    img: "artists/2023-Artists/Erik-Smiley.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Fernando Mardo",
    town: "Arizona",
    country: "USA",
    location: "Arizona, USA",
    insta_handle: "fernyourstruly",
    email_address: "owerart@gmail.com",
    img: "artists/2023-Artists/Fernando-Mardo.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Francis Caraccioli (Frankie)",
    town: "California",
    country: "USA",
    location: " California, USA",
    insta_handle: "death_cloak",
    email_address: "frankiecaraccioli@gmail.com",
    img: "artists/2023-Artists/Francis-Caraccioli.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Gab Lavoie",
    town: "Toronto",
    location: "Toronto",
    insta_handle: "gablavoie",
    email_address: "gablavoie.gl@gmail.com",
    img: "artists/2023-Artists/Gab-Lavoie.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Gaia Leone",
    country: "Italy",
    location: "Italy",
    insta_handle: "gaialeone_ht",
    email_address: "gaia.pisani@hotmail.it",
    img: "artists/2023-Artists/Gaia-Leone.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Gaia Zeta",
    town: "Rome",
    country: "Italy",
    location: "Rome, Italy",
    insta_handle: "gaia_zeta",
    email_address: "gaiazeta.tattoo@gmail.com",
    img: "artists/2023-Artists/Gaia-Zeta.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Gareth Lloyd",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "garethlloyd.cet",
    email_address: "gazzalloyd@yahoo.com",
    img: "artists/2023-Artists/Gareth-Lloyd.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Gemma Pariente",
    town: "California",
    country: "US",
    location: "California, US",
    insta_handle: "gemmapariente",
    email_address: "gemmatattoo@gmail.com",
    img: "artists/2023-Artists/Gemma-Pariente.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Greg Ioakimoglou",
    country: "Greece",
    location: "Greece",
    insta_handle: "greg_ioakimoglou",
    email_address: "Greslo18@hotmail.com",
    img: "artists/2023-Artists/Greg-Ioakimoglou.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Guus Mandigers",
    town: "Eindhoven",
    country: "Netherlands",
    location: "Eindhoven, Netherlands",
    insta_handle: "Mandigerstattoo",
    email_address: "info@trueblue-tattoo.com",
    img: "artists/2023-Artists/Guus-Mandigers.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ivan Antonishev",
    town: "Indiana ",
    location: "Indiana ",
    insta_handle: "ivanantonyshevtattoo",
    email_address: "iatattooer@gmail.com",
    img: "artists/2023-Artists/Ivan-Antonishev.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jade Alexandra",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "jade_alexandra_tattoos",
    email_address: "jadeAlexandra.tl@gmail.com",
    img: "artists/2023-Artists/Jade-Alexandra.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Jaime Eagar",
    town: "JHB",
    country: "RSA",
    location: "JHB, RSA",
    insta_handle: "jaimeeagar3",
    email_address: "JaimeEagar3@gmail.com",
    img: "artists/2023-Artists/Jaime-Eagar.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Jakob Grabner",

    town: "Vienna",
    location: "Vienna",
    insta_handle: "_jakob_grabner_ ",
    email_address: "jakob.grabner@gmx.de",
    img: "artists/2023-Artists/Jakob-Grabner.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jamie Clara",
    town: "Joburg",
    country: "SA",
    location: "Gauteng, SA",
    insta_handle: "jaimec_ink",
    email_address: "jaimeplaystation16@gmail.com",
    img: "artists/2023-Artists/Jamie-Clara.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Jarret Crosson",
    town: "Austin, Texas",
    location: "Austin, Texas",
    insta_handle: "jarretcrosson",
    email_address: "jarretcrosson@gmail.com",
    img: "artists/2023-Artists/Jarret-Crosson.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jason Tyler Grace",
    town: "Portland",
    country: "USA",
    location: "Portland USA",
    insta_handle: "jasontylergrace",
    email_address: "jasontgrace@gmail.com",
    img: "artists/2023-Artists/Jason-Tyler-Grace.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jaxx Shoulder",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "jaxx.tattoo",
    email_address: "shoulderjack@gmail.com",
    img: "artists/2023-Artists/Jaxx-Shoulder.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Jay Tattoo",
    country: "Sweden",
    location: "Sweden",
    insta_handle: "Jaytats.ebs",
    email_address: "Jaytattoo.ebs@gmail.com",
    img: "artists/2023-Artists/Jay-Tattoo.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Jean Le Roux",
    country: "United Kingdom",
    location: "United Kingdom",
    insta_handle: "jeanleroux",
    email_address: "info@goodfortunestudio.co.uk",
    img: "artists/2023-Artists/Jean-Le-Roux.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jef Verhoeven",
    town: "Londrina",
    country: "Brasil",
    location: "Londrina, Brasil",
    insta_handle: "jef_verhoeven",
    email_address: "Jefverhoeventattoo@hotmail.com",
    img: "artists/2023-Artists/Jef-Verhoeven.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jenna Bouma",
    town: "NYC",
    location: "NYC",
    insta_handle: "slowerblack",
    email_address: "slowerblack@gmail.com",
    img: "artists/2023-Artists/Jenna-Bouma.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Joey Schofield",
    town: "Jhb",
    country: "RSA",
    location: "Jhb RSA",
    insta_handle: "joey.inked",
    email_address: "joey.ink8@gmail.com",
    img: "artists/2023-Artists/Joey-Schofield.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Jonathan Reina",
    town: "Gran Canaria",
    country: "Spain",
    location: "Gran Canaria, Spain",
    insta_handle: "jonathanreinatattoo",
    email_address: "jonathanreinatattoo@gmail.com",
    img: "artists/2023-Artists/Jonathan-Reina.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Jorgos Karidas",
    town: "Amsterdam",
    country: "Netherlands",
    location: "Amsterdam, Netherlands",
    insta_handle: "jorgoskaridas",
    email_address: "Jorgoskaridastattoo@gmail.com / info@trueblue-tattoo.com",
    img: "artists/2023-Artists/Jorgos-Karidas.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Kady Wilie",
    town: "Austin",
    country: "Texas",
    location: "Austin, Texas",
    insta_handle: "kadyannetattoo",
    email_address: "kadytattoo@gmail.com",
    img: "artists/2023-Artists/Kady-Wilie.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Katie Gray",
    town: "New York",
    country: "US",
    location: "New York, US",
    insta_handle: "____katie.gray",
    email_address: "Katie.gray.tattoo@gmail.com",
    img: "artists/2023-Artists/Katie-Gray.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Kawika Montoya",
    town: "Costa",
    country: "Rica",
    location: "Costa Rica",
    insta_handle: "k_montoya_tattoo",
    email_address: "kawikamontoya@gmail.com",
    img: "artists/2023-Artists/Kawika-Montoya.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Kwun",
    town: "Montreal",
    country: "Canada",
    location: "Montreal, Canada",
    insta_handle: "Homiekwun",
    email_address: "Homiekwun@gmail.com",
    img: "artists/2023-Artists/Kwun.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Kyle Beyers",
    town: "Jhb",
    country: "RSA",
    location: "Jhb, RSA",
    insta_handle: "kylebtattoo",
    email_address: "kylebtattoo@gmail.com",
    img: "artists/2023-Artists/Kyle-Beyers.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Kyle Kemp",
    town: "JHB",
    country: "RSA",
    location: "JHB, RSA",
    insta_handle: "kylekemptattoo",
    email_address: "info@buzztattoo.co.za",
    img: "artists/2023-Artists/Kyle-Kemp.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Lara Scotton",
    town: "NYC / Milan",
    location: "NYC/MILAN",
    insta_handle: "larascotton",
    email_address: "lara@larascotton.com",
    img: "artists/2023-Artists/Lara-Scotton.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Lars van Zandt",
    town: "Missouri",
    location: "Missouri",
    insta_handle: "lars.van.zandt",
    email_address: "lars.van.zandt@gmail.com",
    img: "artists/2023-Artists/Lars-Van-Zandt.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Lileen van den Berg",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "lilyinksa",
    email_address: "lilyinksa@icloud.com",
    img: "artists/2023-Artists/Lileen-van-den-Berg.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Lino Savage",
    town: "Gauteng",
    country: "SA",
    location: "Gauteng, SA",
    insta_handle: "linosavage_tattoo",
    email_address: "marcelino.pietersen@gmail.com",
    img: "artists/2023-Artists/Lino-Savage.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Lucas Wagner",
    town: "Mannheim",
    country: "Germany",
    location: "Mannheim, Germany",
    insta_handle: "wastedhappyyouth",
    email_address: "email@lucaswagner.de",
    img: "artists/2023-Artists/Lucas-Wagner.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Luke Nicou",
    town: "PE",
    country: "South Africa",
    location: "PE, South Africa",
    insta_handle: "luckyluketattooer",
    email_address: "luckyluketattooer@gmail.com",
    img: "artists/2023-Artists/Luke-Nicou.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Luke Satoru",
    town: "Bangkok",
    country: "Thailand",
    location: "Bangkok, Thailand",
    insta_handle: "luke_satoru",
    email_address: "blackpigstudiobkk@gmail.com",
    img: "artists/2023-Artists/Luke-Satoru.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Luke Smith",
    town: "London",
    country: "UK",
    location: "London, UK",
    insta_handle: "lukesmithtattoo",
    email_address: "lukesmithtattoo@gmail.com",
    img: "artists/2023-Artists/Luke-Smith.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Marco Cavaliere",
    town: "Foggia",
    country: "Italy",
    location: "Foggia, Italy",
    insta_handle: "em_cab",
    email_address: "cavaliere.antipop@gmail.com",
    img: "artists/2023-Artists/Marco-Cavaliere.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Marco Manopola Andreini",
    town: "Arezzo",
    country: "Italy",
    location: "Arezzo, Italy",
    insta_handle: "marcomanopola",
    email_address: "Manopolalvb@gmail.com",
    img: "artists/2023-Artists/Marco-Andreini.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Marigold Petros",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "marigoldtattoos",
    email_address: "marigold.petros@gmail.com",
    img: "artists/2023-Artists/Marigold-Petros.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Mark Drushchenko",
    town: "Moscow",
    country: "Russia",
    location: "Moscow, Russia",
    insta_handle: "markd_tattoo",
    email_address: "markdry95@gmail.com",
    img: "artists/2023-Artists/Mark-Drushchenko.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Matt Cannon",
    town: "Anaheim",
    country: "US",
    location: "Anaheim, US",
    insta_handle: "mattxcannon",
    email_address: "yikesmatt@gmail.com",
    img: "artists/2023-Artists/Matt-Cannon.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Matt Kajiya",
    town: "Cape Town",
    country: "RSA",
    location: "Cape Town RSA",
    insta_handle: "tattooist_mk",
    email_address: "Matthew123.smith24@gmail.com",
    img: "artists/2023-Artists/Matt-Kajiya.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Matthew Oldfield",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "oldfield_tattoos",
    email_address: "matthew.a.oldfield@gmail.com",
    img: "artists/2023-Artists/Matthew-Oldfield.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Max May",
    town: "Santa Rosa",
    country: "California",
    location: "Santa Rosa, California",
    insta_handle: "maxmaytattoo",
    email_address: "maxmaytattoo@gmail.com",
    img: "artists/2023-Artists/Max-May.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Mich Mulder",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg, RSA",
    insta_handle: "babydoll_elle_tattoo",
    email_address: "mich@fallenheroes.co.za",
    img: "artists/2023-Artists/Mich-Mulder.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Melanie No",
    town: "Frankfurt",
    country: "Germany",
    location: "Frankfurt, Germany",
    insta_handle: "_melanie_no_",
    email_address: "tattoo@inkyundstretchy.de",
    img: "artists/2023-Artists/Melanie-No.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Monique Schroeder",
    town: "Bloemfontein",
    country: "SA",
    location: "Bloemfontein, SA",
    insta_handle: "monah_txttoo",
    email_address: "monahschroeder@icloud.com",
    img: "artists/2023-Artists/Monique-Schroeder.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Natasha Moolman",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg, RSA",
    insta_handle: "kief_richardss",
    email_address: "heykitsch@gmail.com",
    img: "artists/2023-Artists/Natasha-Moolman.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Nathan Ferreira",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "natedawg_tattoo",
    email_address: "Natedawgtattoo@gmail.com",
    img: "artists/2023-Artists/Nathan-Ferreira.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Nathan Phillips",
    town: "Pretoria",
    country: "SA",
    location: "Pretoria, SA",
    insta_handle: "nathanphillips_tattoos",
    email_address: "nathanphillipstattoo@gmail.com",
    img: "artists/2023-Artists/Nathan-Phillips.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Nic Lewis",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "nic.fury",
    email_address: "finkdafoe@gmail.com",
    img: "artists/2023-Artists/Nic-Lewis.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Nicholas Mudskipper",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "nicholasmudskipper",
    email_address: "nicholaskeeping@gmail.com",
    img: "artists/2023-Artists/Nicholas-Mudskipper.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Nico Lavoratori",
    town: "Perugia",
    country: "Italy",
    location: "Perugia, Italy",
    insta_handle: "nico_ssts",
    email_address: "nico.lavoratori@gmail.com",
    img: "artists/2023-Artists/Nico-Lavoratori.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Nikki Vasquez",
    country: "Austria",
    location: "Austria",
    insta_handle: "nikki.vasquez",
    email_address: "nikki@lastingmemories.at",
    img: "artists/2023-Artists/Nikki-Vasquez.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Paige Bates",
    town: "Durban",
    location: "Durban",
    insta_handle: "paige_bates_tattoos",
    email_address: "info@trademarktattoos.com",
    img: "artists/2023-Artists/Paige-Bates.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Paige Kroger",
    town: "Pretoria",
    country: "RSA",
    location: "Pretoria, RSA",
    insta_handle: "saucy.snail.soup.soup",
    email_address: "saucysnailsoup@gmail.com",
    img: "artists/2023-Artists/Paige-Kroger.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Panagiotis Ntolas",
    country: "Greece",
    location: "Greece",
    insta_handle: "panosntolas",
    email_address: "panosntolas@hotmail.com",
    img: "artists/2023-Artists/Panagiotis-Ntolas.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Paul Wolk (Dubbs)",
    town: "Toronto",
    country: "Canada",
    location: "Toronto Canada",
    insta_handle: "paulwolk_tl",
    email_address: "blackeyeghost@gmail.com",
    img: "artists/2023-Artists/Paul-Wolk.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Peter Savage",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "slickback_tats",
    email_address: "savageinkza@gmail.com",
    img: "artists/2023-Artists/Peter-Savage.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Rasty Knayles",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg RSA",
    insta_handle: "rastyknayles",
    email_address: "1933classictattoos@gmail.com",
    img: "artists/2023-Artists/Rasty-Knayles.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Rebecca Claxton",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "rebecca_claxton_tattoo",
    email_address: "matriarchtattoos@gmail.com",
    img: "artists/2023-Artists/Rebecca-Claxton.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Resko",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg RSA",
    insta_handle: "rekso_le_hond",
    email_address: "1933classictattoos@gmail.com",
    img: "artists/2023-Artists/Resko.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Richard Moir",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town, SA",
    insta_handle: "ninjabreadboy",
    email_address: "ninjabreadboy08@gmail.com",
    img: "artists/2023-Artists/Richard-Moir.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Richard Phipson",
    town: "Durban",
    country: "RSA",
    location: "Durban, RSA",
    insta_handle: "iconoglyphs",
    email_address: "richardphipson@gmail.com",
    img: "artists/2023-Artists/Richard-Phipson.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Richie Howes",
    town: "Shelly Beach, KZN",
    location: "Shelly Beach, KZN",
    insta_handle: "richietattooer",
    email_address: "richietattooer@gmail.com",
    img: "artists/2023-Artists/Richie-Howes.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Rog",
    town: "johannesburg",
    country: "RSA",
    location: "johannesburg, RSA",
    insta_handle: "theboynamedcrow",
    email_address: "tattoosbyrog@gmail.com",
    img: "artists/2023-Artists/Rog.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Ross Hallam",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "rosshallam",
    email_address: "handstyletattoos@gmail.com",
    img: "artists/2023-Artists/Ross-Hallam-Tattoo.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Ruan Haasbroek",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "ruan_haas",
    email_address: "ruanhaasbroek3@gmail.com",
    img: "artists/2023-Artists/Ruan-Haasbroek.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Sabrina Sawyers",
    town: "Toronto",
    country: "Canada",
    location: "Toronto, Canada",
    insta_handle: "sabrinasawyerstattoos",
    email_address: "SabrinaSawyerstattoos@gmail.com",
    img: "artists/2023-Artists/Sabrina-Sawyers.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Sanne Vaghi",
    country: "Berlin",
    location: "Berlin",
    insta_handle: "sanne_vaghi",
    email_address: "sannevaghi@gmail.com",
    img: "artists/2023-Artists/Sanne-Vaghi.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Sarah Lee",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "sarah_lee_artist",
    email_address: "artbysarahlee@gmail.com",
    img: "artists/2023-Artists/Sarah-Lee.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Sasha Morozov",
    town: "Moscow",
    country: "Russia",
    location: "Moscow, Russia",
    insta_handle: "sashaxmorozov",
    email_address: "a.a.morozov92@gmail.com",
    img: "artists/2023-Artists/Sasha-Morozov.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Sean Lewis",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "seanlewistattooer",
    email_address: "seanlewistattoo@gmail.com",
    img: "artists/2023-Artists/Sean-Lewis.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Sean Smuts",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town SA",
    insta_handle: "Sean_smuts_tattoo",
    email_address: "goodthingstattooco@gmail.com",
    img: "artists/2023-Artists/Sean-Smuts.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Shaun Dean",
    town: "Cape Town",
    country: "SA",
    location: "Cape Town SA",
    insta_handle: "shaunjdean",
    email_address: "shaundeanappointments@gmail.com",
    img: "artists/2023-Artists/Shaun-Dean.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Shay Lanstorm",
    town: "Pretoria",
    country: "SA",
    location: "Pretoria, SA",
    insta_handle: "blind_eye_tattooist",
    email_address: "Shaylanstorm@gmail.com",
    img: "artists/2023-Artists/Shay-Lanstorm.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Sone Jacobs",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "sonny_tattooer",
    email_address: "sonnywactattooer@gmail.com",
    img: "artists/2023-Artists/Sone-Jacobs.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Stara Broda",
    country: "Traveling",
    location: "Traveling",
    insta_handle: "starabroda",
    email_address: "starabroda@gmail.com",
    img: "artists/2023-Artists/Stara-Broda.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Stefan Güntzel",
    town: "St. Gallen",
    country: "Switzerland",
    location: "St. Gallen, Switzerland",
    insta_handle: "stefan_guentzel",
    email_address: "ste_gue@gmx.net",
    img: "artists/2023-Artists/Stefan-Güntzel.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Steve Byrne",
    town: "Austin, Texas",
    location: "Austin Texas",
    insta_handle: "steve_byrne_tattoo",
    email_address: "stevebyrnetattoo@gmail.com",
    img: "artists/2023-Artists/Steve-Byrne.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tamar Thorn",
    town: "Cape Town",
    location: "Cape Town",
    insta_handle: "tamarthorn",
    email_address: "goodthingstattooco@gmail.com",
    img: "artists/2023-Artists/Tamar-Thorn.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Tanner Ramsey",
    town: "LA",
    location: "LA",
    insta_handle: "tannerramsey",
    email_address: "tannerramseytattoo@gmail.com",
    img: "artists/2023-Artists/Tanner-Ramsey.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Taryn Nissen",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "tarynthetattooist",
    email_address: "tarynnissenartist@gmail.com",
    img: "artists/2023-Artists/Taryn-Nissen.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Tarzan",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "tarzan.tribe",
    email_address: "tarzantribe@gmail.com",
    img: "artists/2023-Artists/Tarzan.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Terrel Gouws",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "t_eazy_tattoo",
    email_address: "terrelgouws@icloud.com",
    img: "artists/2023-Artists/Terrel-Gouws.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Thato Mokhadi",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "ink_god_jhb",
    email_address: "dipowersink@gmail.com",
    img: "artists/2023-Artists/Thato-Mokhadi.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Theshen Phillip",
    town: "Gauteng",
    country: "SA",
    location: "Gauteng, SA",
    insta_handle: "savage_ink_sa",
    email_address: "philliptheshen@gmail.com",
    img: "artists/2023-Artists/Theshen-Phillip.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Theunis Coetzee",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "theunisawhetattoos187",
    email_address: "awhetattoo@gmail.com",
    img: "artists/2023-Artists/Theunis-Coetzee.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Thomas Burkhardt",
    town: "Berlin",
    country: "Germany",
    location: "Berlin, Germany",
    insta_handle: "thomasburkhardt",
    email_address: "t.burkhardt@gmx.de",
    img: "artists/2023-Artists/Thomas-Burkhardt.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Thys Uys",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "thys_uys",
    email_address: "thys@fallenheroes.co.za",
    img: "artists/2023-Artists/Thys-Uys.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Tim Hoffmann",
    town: "Mannheim",
    country: "Germany",
    location: "Mannheim, Germany",
    insta_handle: "tim_hoffmann_tattoo",
    email_address: "timhoffmanntattoo@gmail.com",
    img: "artists/2023-Artists/Tim-Hoffmann.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tony Weintraud",
    town: "Pretoria",
    country: "SA",
    location: "Pretoria, SA",
    insta_handle: "onetattootony",
    email_address: "tonycreations@gmail.com",
    img: "artists/2023-Artists/Tony-Weintraud.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tshepang Mashaba",
    town: "Gauteng",
    country: "SA",
    location: "Gauteng, SA",
    insta_handle: "kidt_ink",
    email_address: "hillsidetattoojhb@gmail.com",
    img: "artists/2023-Artists/Tshepang-Mashaba.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Tyler Abner",
    town: "Cincinnati",
    country: "USA",
    location: "Cincinnati, USA",
    insta_handle: "tylerabnertattoo",
    email_address: "ghostpanthertattoo@gmail.com",
    img: "artists/2023-Artists/Tyler-Abner.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Valentin Tatau",
    town: "Berlin",
    country: "Germany",
    location: "Berlin, Germany",
    insta_handle: "valentin.tatau",
    email_address: "vtatauierung@gmail.com",
    img: "artists/2023-Artists/Valentin-Tatau.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Valerio Valanzuolo",
    town: "Rome",
    country: "Italy",
    location: "Rome, Italy",
    insta_handle: "valeriosst",
    email_address: "Sstvalerio@gmail.com",
    img: "artists/2023-Artists/Valerio-Valanzuolo.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Victor B Pastier",
    country: "France",
    location: "France",
    insta_handle: "victorbpastier",
    email_address: "victorbptattoo@gmail.com",
    img: "artists/2023-Artists/Victor-Pastier.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Vincent Riquier",
    town: "Richmond, Virginia",
    country: "USA",
    location: "Richmond, Virginia, USA",
    insta_handle: "deathwitch",
    email_address: "deathwitchtattoo@gmail.com",
    img: "artists/2023-Artists/Vincent-Riquier.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Wesley Gemistaris",
    town: "Eindhoven",
    country: "Netherlands",
    location: "Eindhoven, Netherlands",
    insta_handle: "wesleygemistaris",
    email_address: "wesleygemistaris@gmail.com / info@trueblue-tattoo.com",
    img: "artists/2023-Artists/Wesley-Gemistaris.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Wesley Von Blerk",
    town: "Cape Town",
    country: "RSA",
    location: "Cape Town, RSA",
    insta_handle: "wesley_von_blerk",
    email_address: "wesprez01@gmail.com",
    img: "artists/2023-Artists/Wesley-Von-Blerk.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "WOHCIZM",
    town: "Johannesburg",
    country: "RSA",
    location: "Johannesburg RSA",
    insta_handle: "WOHCIZM",
    email_address: "1933classictattoos@gmail.com",
    img: "artists/2023-Artists/WOHCIZM.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },
  {
    name: "Yadou Trojanowska",
    town: "Warsaw",
    country: "Poland",
    location: "Warsaw, Poland",
    insta_handle: "yadou_tattoo",
    email_address: "yadouinfo@gmail.com",
    img: "artists/2023-Artists/Yadou-Trojanowska.jpg",
    year: "2023",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Zach Thomas",
    town: "Michigan",
    country: "US",
    location: "Michigan, US",
    insta_handle: "Zachthomas_tattooer",
    email_address: "ZachthOmastattoos-Gmail-com",
    img: "artists/2023-Artists/Zach-Thomas.jpg",
    year: "2023",
    cities_attending: ["Cape Town", "Joburg"],
  },
  {
    name: "Zelda Behr",
    town: "Vereeniging",
    country: "RSA",
    location: "Vereeniging, RSA",
    insta_handle: "josy_black_ink",
    email_address: "josyblackink@gmail.com",
    img: "artists/2023-Artists/Zelda-Behr.jpg",
    year: "2023",
    cities_attending: ["Joburg"],
  },

  {
    name: "Aaron Carmody",
    town: "Syracuse",
    country: "New York",
    location: "Syracuse, New York",
    insta_handle: "rad_el_snake",
    email_address: "",
    img: "artists/2024-Artists/Aaron-Carmody-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Adel Nicolette Strydom",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "adelnicolettetattoos",
    email_address: "",
    img: "artists/2024-Artists/Adel-Nicolette-Strydom-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Adrian Caballero",
    town: "Murcia",
    country: "Spain",
    location: "Murcia, Spain",
    insta_handle: "_adrittt",
    email_address: "",
    img: "artists/2024-Artists/Adrian-Caballero-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alan Thompson",
    town: "St. Louis",
    country: "USA",
    location: "St. Louis, USA",
    insta_handle: "alboyee",
    email_address: "",
    img: "artists/2024-Artists/Alan-Thompson-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alastair Magee",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "alastairmagee",
    email_address: "",
    img: "artists/2024-Artists/Alastair-Magee-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alberto Sagripanti",
    town: "Nottingham",
    country: "UK",
    location: "Nottingham, UK",
    insta_handle: "alberto_sagripanti",
    email_address: "",
    img: "artists/2024-Artists/Alberto-Sagripanti-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alex Muller",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "weskuswolf",
    email_address: "",
    img: "artists/2024-Artists/Alex-Muller-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alex Steiger ",
    town: "Wilderness",
    country: "South Africa",
    location: "Wilderness, South Africa",
    insta_handle: "alexsteiger",
    email_address: "",
    img: "artists/2024-Artists/Alex-Steiger-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alexis Hepburn",
    town: "Sydney",
    country: "Australia",
    location: "Sydney, Australia",
    insta_handle: "disintegration23",
    email_address: "",
    img: "artists/2024-Artists/Alexis-Hepburn-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Alice Rowan",
    town: "London",
    country: "UK",
    location: "London, UK",
    insta_handle: "rowanalice_",
    email_address: "",
    img: "artists/2024-Artists/Alice-Rowan-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Arthur GAJDA",
    town: "Saint-Etienne",
    country: "France",
    location: "Saint-Etienne, France",
    insta_handle: "trevis_tattooer",
    email_address: "",
    img: "artists/2024-Artists/Arthur-GAJDA-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ashley Welman",
    town: "Durban",
    country: "South Africa",
    location: "Durban, South Africa",
    insta_handle: "el_duderino87",
    email_address: "",
    img: "artists/2024-Artists/Ashley-Welman-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Beau Brady",
    town: "Coda Messa",
    country: "CA",
    location: "Coda Messa, CA",
    insta_handle: "beauxbrady",
    email_address: "",
    img: "artists/2024-Artists/Beau-Brady-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ben Wight",
    town: "Rochester",
    country: "New York",
    location: "Rochester, New York",
    insta_handle: "benwight_",
    email_address: "",
    img: "artists/2024-Artists/Ben-Wight-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Bernadee Bibble",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "bernadeeawhetattoos187",
    email_address: "",
    img: "artists/2024-Artists/Bernadee-Bibble-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Bert Raymakers",
    town: "Hasselt",
    country: "Belgium",
    location: "Hasselt, Belgium",
    insta_handle: "bertdaggermoon",
    email_address: "",
    img: "artists/2024-Artists/Bert-Raymakers-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Bob Geerts",
    town: "Eindhoven",
    country: "Netherlands",
    location: "Eindhoven, Netherlands",
    insta_handle: "bobeustattoo",
    email_address: "",
    img: "artists/2024-Artists/Bob-Geerts-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Bob Tate",
    town: "Gold Coast",
    country: "Australia",
    location: "Gold Coast, Australia",
    insta_handle: "bobxtate ",
    email_address: "",
    img: "artists/2024-Artists/Bob-Tate-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Byron Barker",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "byronbarkertattoos",
    email_address: "",
    img: "artists/2024-Artists/Byron-Barker-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Caitlyn Long",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "caitlynlong_tattooer",
    email_address: "",
    img: "artists/2024-Artists/Caitlyn-Long-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Calvin Steps",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "calvinsteps",
    email_address: "",
    img: "artists/2024-Artists/Calvin-Steps-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Carlos Zucato",
    town: "London",
    country: "UK",
    location: "London, UK",
    insta_handle: "carloszucato",
    email_address: "",
    img: "artists/2024-Artists/Carlos-Zucato-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Cesar",
    town: "San Francisco",
    country: "USA",
    location: "San Francisco, USA",
    insta_handle: "cesar863",
    email_address: "",
    img: "artists/2024-Artists/Cesar-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Chaz Otto",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "dirtbagxchaz",
    email_address: "",
    img: "artists/2024-Artists/Chaz-Otto-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Chris De Villiers",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "chrisdeviltattoos",
    email_address: "",
    img: "artists/2024-Artists/Chris-De-Villiers-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Clemens Hahn",
    town: "Mannheim",
    country: "Germany",
    location: "Mannheim, Germany",
    insta_handle: "clemens_hahn",
    email_address: "",
    img: "artists/2024-Artists/Clemens-Hahn-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dani Ardila Escobar",
    town: "Karlsruhe",
    country: "Germany",
    location: "Karlsruhe, Germany",
    insta_handle: "ardilaescobar",
    email_address: "",
    img: "artists/2024-Artists/Dani-Ardila-Escobar-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Daniel Feinberg",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "danielfeinberg",
    email_address: "",
    img: "artists/2024-Artists/Daniel-Feinberg-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Daniel Hughes",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "danielhughestattoos",
    email_address: "",
    img: "artists/2024-Artists/Daniel-Hughes-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Danielle Jeanne",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "daniellejeannetattoo",
    email_address: "",
    img: "artists/2024-Artists/Danielle-Jeanne-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Darryl Hart",
    town: "New Brunswick",
    country: "Canada",
    location: "New Brunswick, Canada",
    insta_handle: "dhart_tl",
    email_address: "",
    img: "artists/2024-Artists/Darryl-Hart-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "David Chaston",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "davidchastontattoo",
    email_address: "",
    img: "artists/2024-Artists/David-Chaston-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "David Pollen",
    town: "Bangkok",
    country: "Thailand",
    location: "Bangkok, Thailand",
    insta_handle: "azndavetattoo",
    email_address: "",
    img: "artists/2024-Artists/David-Pollen-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dean Fleischmann",
    town: "San Diego",
    country: "USA",
    location: "San Diego, USA",
    insta_handle: "dean_flash_man",
    email_address: "",
    img: "artists/2024-Artists/Dean-Fleischmann-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Devin Thomas",
    town: "Johannesburg",
    country: "SA",
    location: "Johannesburg, SA",
    insta_handle: "tightropethomas",
    email_address: "",
    img: "artists/2024-Artists/Devin-Thomas-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dimitris Dimopoulos",
    town: "Larisa",
    country: "Greece",
    location: "Larisa, Greece",
    insta_handle: "cobra_tattoo",
    email_address: "",
    img: "artists/2024-Artists/Dimitris-Dimopoulos-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Dok Iriezumi",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "dok_iriezumi",
    email_address: "",
    img: "artists/2024-Artists/Dok-Iriezumi-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Emmanuel Marceau        ",
    town: "Paris",
    country: "France",
    location: "Paris, France",
    insta_handle: "salutmanu_tattoo",
    email_address: "",
    img: "artists/2024-Artists/Emmanuel-Marceau-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Evan Davis",
    town: "Nashville",
    country: "USA",
    location: "Nashville, USA",
    insta_handle: "evandavistattoo",
    email_address: "",
    img: "artists/2024-Artists/Evan-Davis-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Florian Santus ",
    town: "Paris",
    country: "France",
    location: "Paris, France",
    insta_handle: "floriansantus",
    email_address: "",
    img: "artists/2024-Artists/Florian-Santus-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Florian Svaha",
    town: "Bordeaux",
    country: "France",
    location: "Bordeaux, France",
    insta_handle: "florian.svaha",
    email_address: "",
    img: "artists/2024-Artists/Florian-Svaha-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Gareth Lloyd",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "garethlloyd.cet",
    email_address: "",
    img: "artists/2024-Artists/Gareth-Lloyd-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Genna Howard",
    town: "New York",
    country: "USA",
    location: "New York, USA",
    insta_handle: "gennamhoward ",
    email_address: "",
    img: "artists/2024-Artists/Genna-Howard-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Greg Ioakimoglou",
    town: "Alexandroupolis",
    country: "Greece",
    location: "Alexandroupolis, Greece",
    insta_handle: "greg_ioakimoglou",
    email_address: "",
    img: "artists/2024-Artists/Greg-Ioakimoglou-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Guus Mandigers",
    town: "Eindhoven",
    country: "Netherlands",
    location: "Eindhoven, Netherlands",
    insta_handle: "mandigerstattoo",
    email_address: "",
    img: "artists/2024-Artists/Guus-Mandigers-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jake Cortez",
    town: "Wilmington",
    country: "NC. USA",
    location: "Wilmington, NC. USA",
    insta_handle: "jakecortez_tattoos",
    email_address: "",
    img: "artists/2024-Artists/Jake-Cortez-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jaxx Shoulder",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "jaxx.tattoo",
    email_address: "",
    img: "artists/2024-Artists/Jaxx-Shoulder-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jay Tattoo",
    town: "",
    country: "Sweden",
    location: "Sweden",
    insta_handle: "jaytats.ebs",
    email_address: "",
    img: "artists/2024-Artists/Jay-Tattoo-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jef Verhoeven",
    town: "Brussels",
    country: "Belgium",
    location: "Brussels, Belgium",
    insta_handle: "jef_verhoeven",
    email_address: "",
    img: "artists/2024-Artists/Jef-Verhoeven-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jenna Bouma",
    town: "New York",
    country: "USA",
    location: "New York, USA",
    insta_handle: "slowerblack",
    email_address: "",
    img: "artists/2024-Artists/Jenna-Bouma-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jess McCarthy",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "flesh_mechanics",
    email_address: "",
    img: "artists/2024-Artists/Jess-McCarthy-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jessi Preston",
    town: "Portland",
    country: "USA",
    location: "Portland, USA",
    insta_handle: "jessiprestontattoos",
    email_address: "",
    img: "artists/2024-Artists/Jessi-Preston-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Johnny Raindog",
    town: "Copenhagen",
    country: "Denmark",
    location: "Copenhagen, Denmark",
    insta_handle: "tattoo.raindog",
    email_address: "",
    img: "artists/2024-Artists/Johnny-Raindog-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jon James Case",
    town: "Wilderness",
    country: "South Africa",
    location: "Wilderness, South Africa",
    insta_handle: "jonjamescase",
    email_address: "",
    img: "artists/2024-Artists/Jon-James-Case-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jonathan Reina",
    town: "Gran Canaria",
    country: "Spain",
    location: "Gran Canaria, Spain",
    insta_handle: "jonathanreinatattoo",
    email_address: "",
    img: "artists/2024-Artists/Jonathan-Reina-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jordan Yescas",
    town: "Santa Anna",
    country: "CA. USA",
    location: "Santa Anna, CA. USA",
    insta_handle: "jordoe.gdt",
    email_address: "",
    img: "artists/2024-Artists/Jordan-Yescas-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Jorgos Karidas",
    town: "Amsterdam",
    country: "Netherlands",
    location: "Amsterdam, Netherlands",
    insta_handle: "jorgoskaridas",
    email_address: "",
    img: "artists/2024-Artists/Jorgos-Karidas-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Julia Campione",
    town: "Chicago",
    country: "USA",
    location: "Chicago, USA",
    insta_handle: "juliacampione ",
    email_address: "",
    img: "artists/2024-Artists/Julia-Campione-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Justin Rockett",
    town: "Leeds",
    country: "UK",
    location: "Leeds, UK",
    insta_handle: "rockett_Tattoo",
    email_address: "",
    img: "artists/2024-Artists/Justin-Rockett-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Kady Wilie",
    town: "Austin",
    country: "USA",
    location: "Austin, USA",
    insta_handle: "kadyannetattoo",
    email_address: "",
    img: "artists/2024-Artists/Kady-Wilie-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Kaitlin Greenwood",
    town: "Sydney",
    country: "Australia",
    location: "Sydney, Australia",
    insta_handle: "kaitlingreenwood_",
    email_address: "",
    img: "artists/2024-Artists/Kaitlin-Greenwood-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Karolin TothSchuetze",
    town: "Heidelberg",
    country: "Germany",
    location: "Heidelberg, Germany",
    insta_handle: "karoo_dame",
    email_address: "",
    img: "artists/2024-Artists/Karolin-TothSchuetze-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Katie Gray",
    town: "San Francisco",
    country: "USA",
    location: "San Francisco, USA",
    insta_handle: "____katie.gray",
    email_address: "",
    img: "artists/2024-Artists/Katie-Gray-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Kelly Reissner",
    town: "Windhoek",
    country: "Namibia",
    location: "Windhoek, Namibia",
    insta_handle: "khells_bells",
    email_address: "",
    img: "artists/2024-Artists/Kelly-Reissner-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ken Nox",
    town: "Santa Barbra",
    country: "USA",
    location: "Santa Barbra, USA",
    insta_handle: "kenknoxart",
    email_address: "",
    img: "artists/2024-Artists/Ken-Nox-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Kod\u00e4i Muniz ",
    town: "S\u00e3o Paulo",
    country: "Brazil",
    location: "S\u00e3o Paulo, Brazil",
    insta_handle: "_kodificado",
    email_address: "",
    img: "artists/2024-Artists/Kod\u00e4i-Muniz-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Lauren Jayne Gow",
    town: "Cornwall",
    country: "UK",
    location: "Cornwall, UK",
    insta_handle: "laurenjaynegow",
    email_address: "",
    img: "artists/2024-Artists/Lauren-Jayne-Gow-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Lucas de Carvalho",
    town: "",
    country: "Brazil",
    location: "Brazil",
    insta_handle: "casdecarvalho",
    email_address: "",
    img: "artists/2024-Artists/Lucas-de-Carvalho-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Luke Nicou",
    town: "Port Elizabeth",
    country: "South Africa",
    location: "Port Elizabeth, South Africa",
    insta_handle: "luckyluketattooer",
    email_address: "",
    img: "artists/2024-Artists/Luke-Nicou-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Luke Satoru",
    town: "Bangkok",
    country: "Thailand",
    location: "Bangkok, Thailand",
    insta_handle: "luke_satoru",
    email_address: "",
    img: "artists/2024-Artists/Luke-Satoru-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Malte Lichtenberg",
    town: "Mannheim",
    country: "Germany",
    location: "Mannheim, Germany",
    insta_handle: "mlt_tattoo",
    email_address: "",
    img: "artists/2024-Artists/Malte-Lichtenberg-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Marc Schankath",
    town: "Hanover",
    country: "Germany",
    location: "Hanover, Germany",
    insta_handle: "doenerpma.tattoo",
    email_address: "",
    img: "artists/2024-Artists/Marc-Schankath-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Marigold Petros",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "marigoldtattoos",
    email_address: "",
    img: "artists/2024-Artists/Marigold-Petros-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Mark Clifford ",
    town: "Michigan",
    country: "USA",
    location: "Michigan, USA",
    insta_handle: "markcliffordtattoo",
    email_address: "",
    img: "artists/2024-Artists/Mark-Clifford-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Mark Wittewel",
    town: "Amsterdam",
    country: "Netherlands",
    location: "Amsterdam, Netherlands",
    insta_handle: "mark_wittewel",
    email_address: "",
    img: "artists/2024-Artists/Mark-Wittewel-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Martin Breedt",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "dmttattoos",
    email_address: "",
    img: "artists/2024-Artists/Martin-Breedt-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Matthew Oldfield",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "oldfield_tattoos",
    email_address: "",
    img: "artists/2024-Artists/Matthew-Oldfield-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Mattie",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "slow.mattie__tattoo",
    email_address: "",
    img: "artists/2024-Artists/Mattie-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Melanie No",
    town: "Frankfurt",
    country: "Germany",
    location: "Frankfurt, Germany",
    insta_handle: "_melanie_no_",
    email_address: "",
    img: "artists/2024-Artists/Melanie-No-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Nic Lewis",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "nic.fury",
    email_address: "",
    img: "artists/2024-Artists/Nic-Lewis-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Nicholas Mudskipper",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "nicholasmudskipper",
    email_address: "",
    img: "artists/2024-Artists/Nicholas-Mudskipper-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Nick Barnett",
    town: "Richmond",
    country: "USA",
    location: "Richmond, USA",
    insta_handle: "nickxbarnett",
    email_address: "",
    img: "artists/2024-Artists/Nick-Barnett-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Owen Miller",
    town: "San Francisco",
    country: "USA",
    location: "San Francisco, USA",
    insta_handle: "owenmillertattoo",
    email_address: "",
    img: "artists/2024-Artists/Owen-Miller-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Paige Bates",
    town: "Durban",
    country: "SA",
    location: "Durban, SA",
    insta_handle: "paige_bates_tattoos",
    email_address: "",
    img: "artists/2024-Artists/Paige-Bates-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Paige Kroger",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "saucy.snail.soup",
    email_address: "",
    img: "artists/2024-Artists/Paige-Kroger-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Panagiotis Ntolas",
    town: "Larisa",
    country: "Greece",
    location: "Larisa, Greece",
    insta_handle: "panosntolas",
    email_address: "",
    img: "artists/2024-Artists/Panagiotis-Ntolas-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Paul Wolk (Dubbs)",
    town: "Toronto",
    country: "Canada",
    location: "Toronto, Canada",
    insta_handle: "paulwolk_tl",
    email_address: "",
    img: "artists/2024-Artists/Paul-Wolk-(Dubbs)-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Paula Castle",
    town: "Bristol",
    country: "UK",
    location: "Bristol, UK",
    insta_handle: "paulacastletattoos",
    email_address: "",
    img: "artists/2024-Artists/Paula-Castle-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Peter Blim",
    town: "Frankenthal",
    country: "Germany",
    location: "Frankenthal, Germany",
    insta_handle: "peterblimtattoo",
    email_address: "",
    img: "artists/2024-Artists/Peter-Blim-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Richard Moir",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "ninjabreadboy",
    email_address: "",
    img: "artists/2024-Artists/Richard-Moir-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Richie Howes",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "richietattooer",
    email_address: "",
    img: "artists/2024-Artists/Richie-Howes-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Rik Baltussen",
    town: "Eindhoven",
    country: "Netherlands",
    location: "Eindhoven, Netherlands",
    insta_handle: "r.a.w.tattooer",
    email_address: "",
    img: "artists/2024-Artists/Rik-Baltussen-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Roberto Sanchez",
    town: "Seattle",
    country: "USA",
    location: "Seattle, USA",
    insta_handle: "cougartooth",
    email_address: "",
    img: "artists/2024-Artists/Roberto-Sanchez-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Rod Davis",
    town: "Richmond",
    country: "USA",
    location: "Richmond, USA",
    insta_handle: "rod__ink",
    email_address: "",
    img: "artists/2024-Artists/Rod-Davis-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ross Hallam",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "rosshallam",
    email_address: "",
    img: "artists/2024-Artists/Ross-Hallam-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Ruan Haasbroek",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "ruan_haas",
    email_address: "",
    img: "artists/2024-Artists/Ruan-Haasbroek-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Sasha Morozov",
    town: "Moscow",
    country: "Russia",
    location: "Moscow, Russia",
    insta_handle: "sashaxmorozov",
    email_address: "",
    img: "artists/2024-Artists/Sasha-Morozov-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Sean Smuts",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "sean_smuts_tattoo",
    email_address: "",
    img: "artists/2024-Artists/Sean-Smuts-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Shannon Hansen",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "shanhantattoo",
    email_address: "",
    img: "artists/2024-Artists/Shannon-Hansen-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Shaun Dean",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "shaunjdean",
    email_address: "",
    img: "artists/2024-Artists/Shaun-Dean-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Skylar Grove ",
    town: "San Luis Obispo",
    country: "CA. USA",
    location: "San Luis Obispo, CA. USA",
    insta_handle: "skylar_grove",
    email_address: "",
    img: "artists/2024-Artists/Skylar-Grove-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Sone Jacobs",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "sonny_tattooer",
    email_address: "",
    img: "artists/2024-Artists/Sone-Jacobs-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Sophie Mo",
    town: "London",
    country: "UK",
    location: "London, UK",
    insta_handle: "sophiemoillustration",
    email_address: "",
    img: "artists/2024-Artists/Sophie-Mo-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tammy Kim",
    town: "Toronto",
    country: "Canada",
    location: "Toronto, Canada",
    insta_handle: "tammykimtattoo",
    email_address: "",
    img: "artists/2024-Artists/Tammy-Kim-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tanika Marques",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "tanika_tattoos",
    email_address: "",
    img: "artists/2024-Artists/Tanika-Marques-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tanya Swemmer",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "herenowtanya",
    email_address: "",
    img: "artists/2024-Artists/Tanya-Swemmer-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Taryn Nissen",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "tarynthetattooist",
    email_address: "",
    img: "artists/2024-Artists/Taryn-Nissen-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tarzan",
    town: "Bali",
    country: "Indonesia",
    location: "Bali, Indonesia",
    insta_handle: "tarzan.ttt",
    email_address: "",
    img: "artists/2024-Artists/Tarzan-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tash Richards",
    town: "Cape Town",
    country: "South Africa",
    location: "Cape Town, South Africa",
    insta_handle: "kief_richardss",
    email_address: "",
    img: "artists/2024-Artists/Tash-Richards-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Terrel Gouws",
    town: "Durban",
    country: "South Africa",
    location: "Durban, South Africa",
    insta_handle: "t_eazy_tattoo",
    email_address: "",
    img: "artists/2024-Artists/Terrel-Gouws-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Theunis Coetzee",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "theunisawhetattoos187",
    email_address: "",
    img: "artists/2024-Artists/Theunis-Coetzee-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Thys Uys",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "thys_uys",
    email_address: "",
    img: "artists/2024-Artists/Thys-Uys-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tony Weintraud",
    town: "Pretoria",
    country: "South Africa",
    location: "Pretoria, South Africa",
    insta_handle: "onetattootony",
    email_address: "",
    img: "artists/2024-Artists/Tony-Weintraud-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tristan De Pena",
    town: "Lyon",
    country: "France",
    location: "Lyon, France",
    insta_handle: "mushu_ttt",
    email_address: "",
    img: "artists/2024-Artists/Tristan-De-Pena-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Tshepang Mashaba",
    town: "Johannesburg",
    country: "South Africa",
    location: "Johannesburg, South Africa",
    insta_handle: "kidt_ink",
    email_address: "",
    img: "artists/2024-Artists/Tshepang-Mashaba-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Valentin Tatau",
    town: "Berlin",
    country: "Germany",
    location: "Berlin, Germany",
    insta_handle: "valentin.tatau",
    email_address: "",
    img: "artists/2024-Artists/Valentin-Tatau-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Victor B Pastier",
    town: "Nantes",
    country: "France",
    location: "Nantes, France",
    insta_handle: "victorpastier",
    email_address: "",
    img: "artists/2024-Artists/Victor-B-Pastier-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Vincent Riquier",
    town: "Richmond",
    country: "Virginia",
    location: "Richmond, Virginia",
    insta_handle: "deathwitch",
    email_address: "",
    img: "artists/2024-Artists/Vincent-Riquier-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Youness Lagdali",
    town: "Nantes",
    country: "France",
    location: "Nantes, France",
    insta_handle: "maclow",
    email_address: "",
    img: "artists/2024-Artists/Youness-Lagdali-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Zach Thomas",
    town: "Michigan",
    country: "USA",
    location: "Michigan, USA",
    insta_handle: "zachthomas_tattooer",
    email_address: "",
    img: "artists/2024-Artists/Zach-Thomas-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
  {
    name: "Zelda Behr",
    town: "Vereeniging",
    country: "South Africa",
    location: "Vereeniging, South Africa",
    insta_handle: "josy_black_ink",
    email_address: "",
    img: "artists/2024-Artists/Zelda-Behr-Tattoo.jpg",
    year: "2024",
    cities_attending: ["Cape Town"],
  },
]
export default ARTISTS_DATA
